import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import { Flex, Box, Stack, Heading, Text, Button } from '@chakra-ui/core'

import Carousel from '../carousel'

export function RoomItem({
  to,
  images,
  amenities,
  title,
  desc,
  buttonText,
  onClick,
  ...restProps
}) {
  return (
    <Flex direction="column" overflow="hidden" {...restProps}>
      <Carousel borderRadius="15px" flexShrink={0}>
        {images.map((image) => (
          <Box
            key={v4()}
            onClick={onClick}
            backgroundImage={`url(${image})`}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            paddingBottom="65%"
          />
        ))}
      </Carousel>
      <Heading as="h4" fontWeight="bold" fontSize="xl" marginTop={4}>
        {title}
      </Heading>
      {amenities.length !== 0 && (
        <Stack direction="row">
          <Text fontSize="md" opacity="0.7">
            {amenities.join(' · ')}
          </Text>
        </Stack>
      )}
      <Text flex="100%" marginTop={2}>
        {desc}
      </Text>
      <Button
        flexShrink={0}
        variantColor="teal"
        width="fit-content"
        size="md"
        marginTop={2}
        marginLeft="auto"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Flex>
  )
}

RoomItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  buttonText: PropTypes.string,
  images: PropTypes.array,
  amenities: PropTypes.array,
  onClick: PropTypes.func,
}

RoomItem.defaultProps = {
  to: '',
  title: '',
  desc: '',
  buttonText: '',
  images: [],
  amenities: [],
  onClick: () => {},
}
