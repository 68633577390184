import React from 'react'
import { SimpleGrid } from '@chakra-ui/core'

export * from './roomItem'

export function RoomList({ children, ...restProps }) {
  return (
    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={10} minWidth={0} {...restProps}>
      {children}
    </SimpleGrid>
  )
}
