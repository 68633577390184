import React from 'react'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { useSetRecoilState } from 'recoil'

import { bookingParamsState } from '../store'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Article from '../components/article'
import { RoomList, RoomItem } from '../components/roomList'

function RoomsPage({ data }) {
  const { t } = useTranslation()
  const setBookingParamsState = useSetRecoilState(bookingParamsState)

  const handleClick = (id, room) => {
    setBookingParamsState({ id, room, isOpen: true })
  }

  const getImagesFromData = (edgeName) => {
    return data[edgeName].edges.map((edge) => edge.node.childImageSharp.fixed.src)
  }

  return (
    <Layout title={t('rooms.title')}>
      <SEO title={t('rooms.title')} />
      <Article>
        <Section
          id="hiphotel"
          title={t('rooms.hiphotel.title')}
          subtitle={t('rooms.hiphotel.subtitle')}
        >
          <RoomList>
            {t('rooms.hiphotel.rooms', { returnObjects: true }).map((room) => {
              return (
                <RoomItem
                  key={v4()}
                  title={room.title}
                  images={getImagesFromData(room.name)}
                  desc={room.desc}
                  amenities={room.amenities.split('/')}
                  buttonText={t(`commons.bookNow`)}
                  onClick={() => handleClick('230', room.idRoom)}
                />
              )
            })}
          </RoomList>
        </Section>

        <Section
          id="anayjose"
          title={t('rooms.anayjose.title')}
          subtitle={t('rooms.anayjose.subtitle')}
        >
          <RoomList>
            {t('rooms.anayjose.rooms', { returnObjects: true }).map((room) => {
              return (
                <RoomItem
                  key={v4()}
                  title={room.title}
                  images={getImagesFromData(room.name)}
                  desc={room.desc}
                  amenities={room.amenities.split('/')}
                  buttonText={t(`commons.bookNow`)}
                  onClick={() => handleClick('232', room.idRoom)}
                />
              )
            })}
          </RoomList>
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 680, quality: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }

  query {
    charmingGardenView: allFile(
      filter: { relativeDirectory: { eq: "charming-garden-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    charmingOceanView: allFile(
      filter: { relativeDirectory: { eq: "charming-ocean-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    feelingsOceanView: allFile(
      filter: { relativeDirectory: { eq: "feelings-ocean-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    gardenView: allFile(
      filter: { relativeDirectory: { eq: "garden-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    oceanFront: allFile(
      filter: { relativeDirectory: { eq: "ocean-front" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    oceanView: allFile(
      filter: { relativeDirectory: { eq: "ocean-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    superiorGardenView: allFile(
      filter: { relativeDirectory: { eq: "superior-garden-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    twoBedroomSuiteJungleView: allFile(
      filter: { relativeDirectory: { eq: "two-bedroom-suite-jungle-view" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default RoomsPage
